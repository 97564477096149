.body {
	text-align: center;
    font-size: 3em;
    height: 450px;
}

.input{
    height:50px;
    width:450px;
    border-radius: 1em;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 5;
    padding-left: 2em;
   
}

.input:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
    border-bottom-color:rgb(77, 5, 211) ;
    padding: 5px;
    
    
}
.input:focus{
    outline:  none;
}

.seConnecter {
	color: rgb(77, 5, 211);
	font-weight: bold;
	border-color: rgb(77, 5, 211);
	border-radius: 5cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
	background-color: white;
    margin-left: 25em;
}

.seConnecter:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
}

.title{
	color: rgb(66, 6, 177);
    margin-top: 1em;
}

.logoConnexion {
	width: 180px;
	float: center;
	margin-top: 1em;
	text-align: center;
}





@media screen and(max-width:1000px) {
   .body{
       float: none;
    }
   .title{
       float: center;
        font-size: 32pt;
    }

    .input{
        width:290px;
    }
    .seConnecter{
        margin-left: 15em;
    }
}


