.bodyUrl{
    margin-top: 1em;
}

.ajoutPushMail{
    margin-top: 3.5em;
    margin-left:245px;
    background-color:rgb(226, 216, 245);
    border-radius: 1em;
    padding: 1em;
    
}

.divUrl{
    background-color:rgb(226, 216, 245);
    border-radius: 1em;
    margin-left:245px;
    margin-bottom: 1em;
    
   
}

.page{
//  float: right;
margin-right: 8em;
}

.urlTitre{
    font-size: 18pt;
    color: rgb(66, 6, 177);
     margin-right: 25em;
}



.pageTitre{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    // float: right;
    // margin-right: 5em;
}



.frequence{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-right: 3em;
    margin-left: 0.5em;
}

.labelAdd{
    font-size: 16pt;
    margin-right: 1em;
    margin-left: 0.5em;
}

.modeTitre{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-right: 3em;
}

.formatTitre{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    // margin-left: 3em;
     margin-right: 3em;
}
.modeAuto{
    // float: right;
    margin-right: 5em;
    margin-left:5em;
   
}
.modeManu{
    // float: right;
    margin-right: 6em;
    margin-left:6em;
   
}
// .format{
//     // float: right;
//     // margin-right:6em;
//     //  margin-left:5em;
// }
.updateModeAuto{
    float:right;
    background-color: white;
    border: none;
    border-radius: 1em;
    padding: 0.5em;
    margin-left: 5em;
    margin-right: 4em;
}

.updateModeManu{
    float:right;
    background-color: white;
    border: none;
    border-radius: 1em;
    padding: 0.5em;
    margin-left: 6em;
    margin-right: 5em;
}

.updateFormat{
    float: right;
    // margin-right:5em;
    // margin-left:10em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.5em;
}

.formatAdd{
    margin-left: 0.5em;
    padding: 0.5em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    margin-left: 2em;
}


.updateMode:hover{
    background-color: rgb(212, 193, 248);
}


.url{
    // margin-left:0.5em;
    // border-top: solid 3px white;
    background-color:rgb(226, 216, 245);
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 0.5em;
    border: none;
    border-radius: 1em;
    width: 45em;
    margin-left: 1em;
    margin-right: 5em;
    // font-size: 10pt;
    
}

.url:hover{
    background-color:white;
}


.ligne{
    border-top: solid 3px white;
    margin-right: 1em;
    margin-left: 1em;
}

.boutonModifUrl{
    background-color: white;
    border:none;
    float: right;
    margin-right: 0.5em;
}
.boutonModifUrl:hover{
    background-color: rgb(212, 193, 248);
}
.space{
    float: right;
}
.iconeModif{
    width: 20px;
    height: 20px;
}

.inputUrl{
    height:30px;
    width: 45em;
    border-radius: 1em;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 5;
    padding-left: 1em;
    margin-right: 2em;
    margin-left: 1em;
}


.inputOrder{
    // float: right;
    text-align: center;
    // margin-right: 9em;
    width: 3em;
    height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
}

.inputOrderAdd{
    text-align: center;
    width: 3em;
    height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    margin-left: 3em;
}

.checkbox{
    margin-right: 4.5em;
}
.boutonPushMail{
	color: rgb(77, 5, 211);
	font-weight: bold;
	border-color: rgb(77, 5, 211);
	border-radius: 5cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
	background-color: white;
}

.boutonPushMail:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
}

.inputUpdate{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.5em;
}

.selectUpdate{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.5em;
    width: 13em;
}

.selectModeAdd{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.3em;
    width: 8em;
    margin-left: 5em;
}

.boutonActivationUrl{
    background-color: white;
    border: none;
    border-radius: 1em;
    padding: 0.5em;
}
.boutonActivationUrl:hover{
    background-color: rgb(212, 193, 248);
}

.boutonEnregistrer{
    background-color: white;
    border: none;
    float: right;
    margin-right: 2em;
    border-radius: 1em;
    padding: 0.5em;

}
.boutonEnregistrer:hover{
    background-color: rgb(212, 193, 248);
}

.separateurUrl{
    border-top: solid 3px white;
    margin-right: 1em;
    margin-left: 1em;
    margin-top:0.5em ;
    margin-bottom:0.5em;
}

.separateur{
    border-top: solid 3px white;
    width: 100%;
    margin-top:0.5em ;
    margin-bottom:0.5em;
}

.div{
    float: right;
}

.boutonAjoutUrl {
    width: 200px;
	color: rgb(77, 5, 211);
	font-weight: bold;
    border-color: rgb(77, 5, 211);
	border-radius: 2cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px;
	background-color: white;
    margin-top: 1em;
    
}

.boutonAjoutUrl:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
    
}

.boutonGenerate{
    background-color:white;
    color:rgb(77, 5, 211);
    border-color: rgb(77, 5, 211);
    border-radius: 1em;
    float:right;
    padding: 0.5em;
    margin-right: 0.5em;
}

.boutonGenerate:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
    padding: 10px;
    
}

@media screen and(min-width:0px) and(max-width:780px) {
    .divUrl{
        height: 100px;
        margin-left:155px;
    }

    .labelurl{
        font-size: 13pt;
    }

    // .url{
    //     font-size: 6.5pt;
    // }
  
}


@media screen and(min-width:781px) and(max-width:1200px) {
    .divUrl{
        height: 100px;
        margin-left:155px;
    }

    .labelurl{
        font-size: 13pt;
    }

    .url{
        font-size: 6.5pt;
    }
  
}






