.id{
    font-size: 18pt;
    color: rgb(66, 6, 177);
 
}

.nom{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-left: 2em;
    margin-right: 2em;
}

.boutonEnregistrer{
    background-color: white;
    border: none;
    float: right;
    margin-right: 2em;
    border-radius: 1em;
    padding: 0.5em;
}
.boutonEnregistrer:hover{
    background-color: rgb(212, 193, 248);
}

.inputAdd{
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.5em;
}