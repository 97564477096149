.menu  {
    float:left;
    width:240px;
    height: 550px;
    background-color:#9966FF;
    border-radius: 1em;
    text-align: center;
    padding-top: 1em;
    margin-top: 3.5em;
    position: absolute;
    
}

.label{
        font-size: 20pt;
        color:white;
    }


.choix{
    width: 200px;
    height: 30px; 
    border: none;
    border-radius: 1em;
    padding-left: 1em;
    
}

.choix:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
}

.choix:focus{
    outline:  none;
}



.select{
    width: 200px;
    height: 30px; 
    border: none;
    border-radius: 1em;
    padding-left: 1em;
    
}

.select:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
}

.select:focus{
    outline:  none;
}

.bouton {
    width: 200px;
	color: rgb(77, 5, 211);
	font-weight: bold;
    border: none;
	border-radius: 2cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px;
	background-color: white;
    margin-top: 1em;
    
}

.bouton:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
    padding: 10px;
}

// .bouton:focus{
//     background-color: rgb(66, 6, 177);
//     padding: 10px;
//     color: white;
// }

.bodyUpdate{
    margin-left: 15.5em;
    margin-top: 3.5em;
    background-color: rgb(226, 216, 245);
    border-radius: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}

.id{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-left: 1em;
    margin-right: 3em;
}

.name{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-left: 2em;
    margin-right: 3em;
}

.boutonModif{
    background-color: rgb(226, 216, 245);
    border-color: rgb(226, 216, 245);
    float: right;
    margin-right: 5em;
}
.iconeModif{
    width: 20px;
    height: 20px;
}

    .selected {
        background-color: rgb(66, 6, 177);
        padding: 10px;
        color: white;
        width: 200px;
        font-weight: bold;
        border: none;
        border-radius: 2cm;
        font-family: Arial, Helvetica, sans-serif;
        padding: 10px;
        margin-top: 1em;
    }

.sousMenu{
    border-top: solid 3px white;
    width: 13em;
    margin:1em ;
}




@media screen and(max-width:1200px) {

    .menu  {
        float:left;
        width:150px;
    }
    .select{
        width: 130px;
        height: 30px; 
        
    }
    .sousMenu{
        width: 130px;
        height: 30px; 
    }
    .selected{
        width: 130px;
        height: 30px; 
    }
    .bouton{
        width: 130px;
        height: 30px; 
    }
}










