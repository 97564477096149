.header {
	color: black;
	border-bottom: solid 3px rgb(226, 216, 245);
	display: flow-root;
	flex-direction: row;
	align-items: center;
	text-align: center;
}



.titre{
    color: rgb(66, 6, 177);
	font-size: 28pt;
	// text-align: center;
	// margin-left: 6em;
	// float: center;
}

.logo {
	width: 180px;
	float: left;
	// text-align: left;
}

.seDeconnecter {
    float: right;
	color: rgb(77, 5, 211);
	font-weight: bold;
	border-color: rgb(77, 5, 211);
	border-radius: 5cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
	background-color: white;
}

.seDeconnecter:hover{
    cursor:pointer;
    background-color:rgb(226, 216, 245);
}


@media screen and(max-width:1000px) {

	 .logo {
		width: 130px;
		float: left;
		margin-bottom: 1em;
	}
	.titre{
		font-size: 20pt;
		float: left;
		margin-right: 5em;
	}

	.seDeconnecter {
		float: right;
		padding: 10px;
	}
	
	
 }

