

.bodyEmail{
    // padding-top: 3em;
    margin-left: 15.5em;
    margin-top: 3em;
    background-color: rgb(226, 216, 245);
    border-radius: 1em;
    padding-bottom: 1em;
    padding-top: 1em;
}


.email{
    border-bottom: solid 3px white;
    padding-top: 0.5cm;
    padding-bottom: 0.5cm;
    margin-left: 1em;
    width: 71em;
    height: 0.5cm;
}



.boutonModif{
    background-color: white;
    border: none;
    float: right;
    // margin-right: 2em;
}

.boutonModif:hover{
    background-color: rgb(212, 193, 248);
}

.iconeModif{
    width: 20px;
    height: 20px;
}


.activation{
    margin-right: 20em;
    float: right;
    
}
.boutonActivation{
    background-color: white;
    border: none;
    float: right;
    margin-right: 23em;
    border-radius: 1em;
    padding: 0.5em;
}
.boutonActivation:hover{
    background-color: rgb(212, 193, 248);
}

.boutonEnregistrer{
    background-color: white;
    border: none;
    float: right;
    margin-right: 2em;
    border-radius: 1em;
    padding: 0.5em;
}
.boutonEnregistrer:hover{
    background-color: rgb(212, 193, 248);
}
.inputMail{
    height:30px;
    width:400px;
    border-radius: 1em;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 5;
    padding-left: 2em;
}
.inputMail:hover{
    cursor:pointer;
    border-bottom-color:rgb(77, 5, 211) ;
    
    
}


.nom{
    font-size: 18pt;
    color: rgb(66, 6, 177);
    margin-left: 2em;
    margin-right: 2em;
}

.inputAddEmail{
    width: 30em;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 1em;
    padding: 0.5em;
}

.msg{
    margin-left: 2em;
}

.boutonAjoutEmail {
    width: 200px;
	color: rgb(77, 5, 211);
	font-weight: bold;
    border-color: rgb(77, 5, 211);
	border-radius: 2cm;
    font-family: Arial, Helvetica, sans-serif;
    padding: 8px;
	background-color: white;
    margin-top: 1em;
    float: right;
    // margin-top: 4em;
}

.boutonAjoutEmail:hover{
    background-color:rgb(226, 216, 245);
    padding: 10px;
}


@media screen and(max-width:1000px) {
    .siteName{
        margin-left: 8em;
        margin-top: 1em;
    }

    .d1{
        margin-left: 10em;
    }

   
  
}

