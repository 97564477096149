.separ{
    border-top: solid 3px white;
    width: 10em;
    margin:1em ;
}
.separ2{
    border-top: solid 3px white;
    width: 20em;
}

.separ3{
    border-top: solid 3px white;
    margin: 0.5em;
    width: 65em;
}


.dateRapport{
    margin-left: 1em;
    margin-right: 2em;
    color: rgb(66, 6, 177);
    font-size: 18pt;
}

.pdfnom{
    color: rgb(66, 6, 177);
    font-size: 18pt;
}

.date{
    margin-left: 1em;
}

.fileName{
    color: black;
   
}
.link{
    text-decoration:none; 
}

.fileName:hover{
    color:rgb(66, 6, 177);
}

.nomRapport{
    font-size: 20pt;
    color: rgb(52, 4, 143);
    margin-right: 3em;
    margin-left: 0.5em;
}

.textarea{
    border-radius: 1em;
    padding:0.5em;
}

.boutonSupp{
    background-color: white;
    border:none;
    float: right;
    margin-right: 0.5em;
}
.boutonSupp:hover{
    background-color: rgb(212, 193, 248);
}

.iconeSupp{
    width: 20px;
    height: 20px;
}

.separMail{
    border-top: solid 3px white;
    width: 80em;
    margin:1em ;
}

.mailaffiche{
    margin: 1em;
}

.boutonEnregistrerEmail{
    background-color: white;
    border: none;
    margin-left: 2em;
    border-radius: 1em;
    padding: 0.5em;
}


.boutonEnregistrerEmail:hover{
    background-color: rgb(212, 193, 248);
}

.nbRapport{
    font-size: 18pt;
    margin: 1em;
}